"use client";
import Link from "next/link";
import Image from "next/image";
import { useRouter, usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import { useCart } from "@/context/CartContext";
import { fetchCart } from "@/utils/Shopify/products";
import CartModal from "./popUpSection/cartModel";
import { S3_BASE_URL } from "@/utils/constants";
import { Product } from "@/types/product";

interface HeaderProps {
  products: Product[];
}

export default function Header({ products }: HeaderProps) {
  const router = useRouter();
  const pathName = usePathname();
  const [showProducts, setShowProducts] = useState(false);
  const [showLearn, setShowLearn] = useState(false);
  const [showTopLabel, setShowTopLabel] = useState(true);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showSearch, setShowSearch] = useState(false); // New state for search section
  const { cart, itemCount, setCart, setItemCount } = useCart();
  const [cartShowModel, setCartShowModel] = useState<boolean>(false);
  const [cartData, setCartData] = useState<{
    cartId: string | null;
    checkoutId: string | null;
  }>({
    cartId: null,
    checkoutId: null,
  });
  // New state for mobile menu

  const onProductClick = (slug: string) => {
    router.push(`/shop/${slug}`);
  };

  const toggleMobileMenu = () => {
    setShowMobileMenu((prev) => {
      if (!prev) {
        document.documentElement.style.overflow = "hidden";
      } else {
        document.documentElement.style.overflow = "";
      }
      return !prev;
    });
  };

  useEffect(() => {
    return () => {
      document.documentElement.style.overflow = ""; // Restore scrolling on unmount
    };
  }, []);

  const toggleSearch = () => {
    setShowSearch((prev) => !prev); // Toggle search section visibility
  };

  const handleAccordionToggle = () => {
    const accordionBody = document.querySelector(
      "#collapseOne .accordion-body"
    );
    if (accordionBody) {
      // Scroll the accordion body to the top
      accordionBody.scrollTop = 0;
    }
  };

  const handleBagClick = async () => {
    // if (typeof window.fbq === "function") {
    //   window.fbq("track", "Pageview - Cart Page");
    // }

    window.dataLayer?.push({
      event: "View-Cart",
      ecommerce: {
        items: cart,
      },
    });
    const { cartId, checkoutId } = cartData;
    if (cartId) {
      const updatedCart = await fetchCart(cartId);
      setCart(updatedCart);
      setItemCount(
        updatedCart.lines.edges.reduce(
          (total: any, edge: any) => total + edge.node.quantity,
          0
        )
      );
    }
    handleCartShow();
  };
  const handleCartShow = () => {
    setCartShowModel(true);
  };
  const handleCartClose = () => {
    setCartShowModel(false);
  };

  return (
    <>
      <CartModal cartShow={cartShowModel} handleClose={handleCartClose} />

      <header className="header d-none d-sm-block">
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <button
              className="navbar-toggler menu-icon-section even-measure"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo01"
              aria-controls="navbarTogglerDemo01"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon custom-toggler-icon"></span>

              <span
                className="position-absolute top-0 end-0 me-3 mt-2 float-end"
                onClick={() => setShowTopLabel(false)}
              >
                {" "}
                x{" "}
              </span>
            </button>

            <div
              className="collapse navbar-collapse flex-grow-0 "
              id="navbarTogglerDemo01"
            >
              <ul className="main-menu navbar-nav mb-2 mb-lg-0">
                <li className="nav-item shop-link">
                  <a className="nav-link" href="/set">
                    Shop
                  </a>
                  {/* productss */}
                  <div className="drop-menu-wrap shop-dropdown-menu">
                    <div className="container">
                      <ul className="menu-prod-list d-flex align-items-center justify-content-between">
                        {products
                          ?.filter(
                            (product: Product) => product.slug !== "tfl-ball"
                          )
                          .map((product: Product, index: number) => (
                            <li key={index}>
                              <Link
                                href={`/${product.slug}`}
                                className="text-center"
                              >
                                <Image
                                  src={`${S3_BASE_URL}${product?.desktop_image}`}
                                  width="165"
                                  height="150"
                                  alt="Image"
                                  style={{
                                    border: "1px solid #848484",
                                    borderRadius: "14px",
                                  }}
                                />
                                <h5 className="mpl-name">{product.name}</h5>
                                <div
                                  className=""
                                  dangerouslySetInnerHTML={{
                                    __html: product.description,
                                  }}
                                />
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="nav-item learn-link">
                  <a className="nav-link" href="/learn">
                    Learn
                  </a>
                  <div className="drop-menu-wrap learn-dropdown-menu">
                    <div className="container">
                      <div className="row">
                        <div className="col-6">
                          <ul className="learn-menu">
                            <li>
                              <Link href="/howto">How-to Videos</Link>
                            </li>
                            <li>
                              <Link href="/manuals-safety">
                                Product Manuals
                              </Link>
                            </li>
                            <li>
                              <Link href="/learn">Blog</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-6">
                          <Image
                            src="/assets/images/learn-nav-section.jpg"
                            alt=""
                            width="810"
                            height="325"
                            quality={100}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/our-story">
                    About
                  </a>
                </li>
              </ul>
            </div>
            <a
              className="navbar-brand text-center ms-sm-auto me-0 me-sm-auto"
              href="/"
            >
              <Image
                src="/assets/images/alethaLogoWhite.svg"
                alt="Aletha Logo"
                width="130"
                height="76"
                className="h-auto"
              />
            </a>
            <div className="d-flex justify-content-center even-measure">
              {/* <button className="btn me-2" onClick={toggleSearch}>
                <Image
                  src="/assets/images/v2/search-icn.svg"
                  alt="Search Icon"
                  width="18"
                  height="22"
                />
              </button> */}
              <button
                className="btn"
                style={{
                  position: "relative",
                  padding: "0",
                  border: "none",
                  background: "none",
                }}
              >
                <Image
                  src="/assets/images/v2/cart-icn.svg"
                  className="bag-btn"
                  alt="Cart Icon"
                  width="18"
                  height="26"
                  onClick={handleBagClick}
                />
                {itemCount > 0 && (
                  <span
                    style={{
                      position: "absolute",
                      top: "-12px",
                      right: "-14px",
                      backgroundColor: "red",
                      color: "white",
                      borderRadius: "50%",
                      padding: "2px 6px",
                      fontSize: "12px",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {itemCount}
                  </span>
                )}
              </button>
            </div>
          </nav>
        </div>
      </header>

      {/* mobile header */}
      <header className="header pt-5 pb-3 d-block d-sm-none">
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <button
              className="navbar-toggler menu-icon-section even-measure"
              type="button"
              aria-expanded={showMobileMenu} // Reflects current state
              aria-label="Toggle navigation"
              onClick={toggleMobileMenu} // Toggles menu visibility
            >
              {/* Conditionally render the hamburger or close (X) icon */}
              {showMobileMenu ? (
                <>
                  <Image
                    src="/assets/images/new-image/close-icon.png" // Ensure you have this image
                    alt="Hamburger Icon"
                    width="24"
                    height="24"
                  />
                </>
              ) : (
                <span className="navbar-toggler-icon custom-toggler-icon">
                  {/* Hamburger icon */}
                </span>
              )}
            </button>

            <a
              className="navbar-brand text-center ms-sm-auto me-0 me-sm-auto"
              href="/"
            >
              <Image
                src="/assets/images/alethaLogoWhite.svg"
                alt="Aletha Logo"
                width="100"
                height="76"
                className="h-auto"
              />
            </a>

            <div className="d-flex justify-content-center even-measure">
              {/* <button className="btn me-2" onClick={toggleSearch}>
                <Image
                  src="/assets/images/v2/search-icn.svg"
                  alt="Search Icon"
                  width="18"
                  height="22"
                />
              </button> */}
              <button
                className="btn"
                style={{
                  position: "relative",
                  padding: "0",
                  border: "none",
                  background: "none",
                }}
              >
                <Image
                  className="bag-btn"
                  onClick={handleBagClick}
                  src="/assets/images/v2/cart-icn.svg"
                  alt="Cart Icon"
                  width="18"
                  height="26"
                />
                {itemCount > 0 && (
                  <span
                    style={{
                      position: "absolute",
                      top: "-15px",
                      right: "-15px",
                      backgroundColor: "red",
                      color: "white",
                      borderRadius: "50%",
                      padding: "2px 6px",
                      fontSize: "12px",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {itemCount}
                  </span>
                )}
              </button>
            </div>
          </nav>
        </div>
      </header>

      {/* Conditionally render the mobile menu based on the state */}
      {showMobileMenu && (
        <>
          <div className="black-overlay" onClick={toggleMobileMenu}></div>
          <div className="mobile-lists d-block d-sm-none">
            <div className="accordion mobile-product-nav" id="accordionExample">
              <div className="accordion-item bg-transparent border-0">
                <h3 className="accordion-header border-top">
                  <button
                    className="accordion-button collapsed bg-transparent text-white"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                    onClick={handleAccordionToggle}
                  >
                    <Link
                      href="/set"
                      className="text-decoration-none menu-list text-white"
                    >
                      Shop
                    </Link>
                  </button>
                </h3>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body bg-transparent text-white mobile-body">
                    <div>
                      <div className="row">
                        {/* Product Links */}
                        {products
                          ?.filter(
                            (product: Product) => product.slug !== "tfl-ball"
                          ) // Filter out the product with slug 'tfl-ball'
                          .map((product: Product, index: number) => (
                            <div className="col-6" key={index}>
                              <Link
                                href={`/${product.slug}`}
                                className="text-center text-decoration-none text-white d-flex flex-column align-items-center"
                                onClick={(e) => {
                                  if (
                                    window.location.pathname ===
                                    `/${product.slug}`
                                  ) {
                                    e.preventDefault();

                                    setShowMobileMenu(false);
                                    document.documentElement.style.overflow =
                                      "";
                                  } else {
                                    setShowMobileMenu(false);
                                    document.documentElement.style.overflow =
                                      "";
                                  }
                                }}
                              >
                                <Image
                                  src={`${S3_BASE_URL}${product?.mobile_image}`}
                                  width="165"
                                  height="150"
                                  alt={product?.name}
                                  style={{
                                    border: "1px solid #848484",
                                    borderRadius: "14px",
                                  }}
                                />
                                <h5 className="product-name mt-2">
                                  {product?.name}
                                </h5>
                                <div
                                  className=""
                                  dangerouslySetInnerHTML={{
                                    __html: product?.description,
                                  }}
                                />
                              </Link>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="accordion-item bg-transparent border-0">
                <h3 className="accordion-header border-top">
                  <button
                    className="accordion-button collapsed bg-transparent text-white"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <Link
                      href="#"
                      className="text-decoration-none text-white menu-list"
                    >
                      Learn
                    </Link>
                  </button>
                </h3>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body bg-transparent text-white">
                    <div className="ms-4">
                      <div className="mb-2">
                        <Link
                          href="/howto"
                          className="text-white learn-list text-decoration-none"
                        >
                          How-to Videos
                        </Link>
                      </div>
                      <div className="mb-2">
                        <Link
                          href="/manuals-safety"
                          className="text-white learn-list text-decoration-none"
                        >
                          Product Manuals
                        </Link>
                      </div>
                      <div>
                        <Link
                          href="/learn"
                          className="text-white learn-list text-decoration-none"
                        >
                          Blog
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Link
                href="/our-story"
                className="text-decoration-none menu-list text-white"
              >
                <div className="accordion-item bg-transparent border-0">
                  <h3 className="accordion-header border-top">
                    <button
                      className="accordion-button no-icn collapsed bg-transparent text-white"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <p className="menu-list text-white mb-0">About</p>
                    </button>
                  </h3>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse border-0"
                    data-bs-parent="#accordionExample"
                  ></div>
                </div>
              </Link>
            </div>
          </div>
          <style jsx>{`
            .black-overlay {
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              height: 100vh;
              background-color: #000;
              z-index: 999;
              opacity: 1;
            }

            .accordion-collapse {
              overflow: hidden;
              transition: height 0.3s ease;
            }

            .accordion-collapse.show {
              height: auto;
            }
            @media (min-width: 769px) {
              .black-overlay {
                display: none;
              }
            }
          `}</style>
        </>
      )}

      {/* search */}
      {/* 
      <div className="search-wrapper container mt-5">
        <div className="row px-3">
          <div className="col-10 col-sm-11">
            <input type="text" name="" id="" className="control-form text-white bg-transparent w-100" placeholder="search " />
          </div>
          <div className="col-2 col-sm-1 d-flex align-items-center">
            <Image
              src="/assets/images/new-image/close-icon.png" // Ensure you have this image
              alt="Hamburger Icon"
              width="24"
              height="24"
            />
          </div>
        </div>
        <div className="d-flex flex-row px-3 my-4">
          <div className="tags">
            <p className="mb-0">the Mark</p>
          </div>
          <div className="tags">
            <p className="mb-0">the Orbit</p>
          </div>
          <div className="tags">
            <p className="mb-0">hip pain</p>
          </div>
        </div>
      </div> */}

      {/* Conditionally render the search section */}
      {showSearch && (
        <div className="search-wrapper mt-5">
          <div className="container">
            <div className="row px-3">
              <div className="col-10 col-sm-11">
                <input
                  type="text"
                  name="search"
                  className="control-form text-white bg-transparent w-100"
                  placeholder="Search..."
                />
              </div>
              <div className="col-2 col-sm-1 d-flex align-items-center">
                <Image
                  src="/assets/images/new-image/close-icon.png"
                  alt="Close Search Icon"
                  width="24"
                  height="24"
                  onClick={toggleSearch} // Close search when clicked
                />
              </div>
            </div>

            <p className="mb-0 text-white popular">Popular Searches</p>

            <div className="d-flex flex-row px-3 my-4">
              <div className="tags">
                <p className="mb-0">the Mark</p>
              </div>
              <div className="tags">
                <p className="mb-0">the Orbit</p>
              </div>
              <div className="tags">
                <p className="mb-0">hip pain</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
